import { render, staticRenderFns } from "./DAResidue.vue?vue&type=template&id=2d43d804&scoped=true&"
import script from "./DAResidue.vue?vue&type=script&lang=js&"
export * from "./DAResidue.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d43d804",
  null
  
)

export default component.exports