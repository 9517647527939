<template>
    <div class="absolute w-17 h-17" >
          <small class="absolute top-12 -left-2">ERS</small>
          <div class="bg-black absolute rounded-full w-2 h-2 top-12 -right-2"></div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>