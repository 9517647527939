<template>
    <div class="absolute w-24">
        <div class="absolute w-24 h-1 bg-red-500 transform rotate-45" ></div>
        <div class="absolute w-24 h-1 bg-red-500 transform -rotate-45"></div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>