<template>
  <div class="w-full h-full px-5 py-5">
    <div>
         <div class="flex justify-between items-center">
          <span class="font-sans font-medium">DIAGRAMA DENTAL</span>
         </div>
         <div>
           <da-odontogram :id="px.person.id_person" has-edited></da-odontogram>
         </div>
      </div>
  </div>
</template>

<script>
import DAOdontogram from '../../../core/components/global/odontogram/DAOdontogram.vue';

export default {
  props: {
    px: {
      type: Object,
      default: function(){return {}}
    }
  },
  data: function() {
    return {
    }
  },
  components: {
    'da-odontogram': DAOdontogram
  },
}
</script>

<style>

</style>