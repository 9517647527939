<template>
    
    <div class="absolute w-17 h-17" >
          <div class="absolute bottom-8 w-17 h-1 bg-blue-500" ></div>
          <div class="absolute left-0 bottom-6 w-1 h-5 bg-blue-500" ></div>
          <div class="absolute right-0 bottom-6 w-1 h-5 bg-blue-500" ></div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>