<template>
    <div class="absolute w-17 h-17" >
          <small class="absolute top-0 left-0">CD</small>
          <div class="bg-red-500 absolute rounded-full w-2 h-2 top-0 right-0"></div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>